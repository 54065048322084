#contactText {
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
}

#contactForm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

::placeholder {
    color: #000;
}

.contactFormInput {
    margin-top: 1%;
    margin-bottom: 1%;
    margin-left: 1%;
    margin-right: 1%;
    background-color: #A5ACAF;
    border: 2px solid #fff;
}

#contactFormTextArea {
    resize: none;
}

#contactFormSubmitBTN {
    color: #000;
}

#contactFormSubmitBTN:hover {
    background-color: #000;
    color: #fff;
}
