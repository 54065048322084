/* 
* CSS for Matt Bagley's DateMeDoc 
*/

/* 
* Media Queries - Mobile Phones & Tablets
*/
@media (max-width: 400px) {
    h1 {
        font-size: 1em;
    }

    p, li {
        font-size: .5em;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

@media (max-width: 600px) {
    h1 {
        font-size: 1.5em;
    }

    p, li {
        font-size: 1em;
    }

    img {
        max-width: 100%;
        height: auto;;
    }
}

@media (min-width: 601px) {
     h1 {
        font-size: 2em;
    }

    p, li {
        font-size: 1.5em;
    }

    img {
        max-width: 100%;
        height: auto;
    }
}

/* 
* Rest of DateMeDoc CSS
*/
#parentDiv_flex {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
    color: white;
}

#parentDiv_grid {
    display: grid;
    grid-column-gap: .25em;
    grid-row-gap: .25em;
    grid-template-columns: 25% 25%;
    justify-content: center;
    align-content: center;
}

#childDiv_flex {
    align-self: center;
    justify-self: center;
    max-width: 75%;
}

#greenFlags h1 {
    color: green;
}