#rmrScreen {
    width: 100%;
}

#header {
    text-align: center;
    justify-self: center;
    align-self: center;
}

#daysInOffice {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: #fff;
    width: 100%;
}

#daysRadio {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.rmrDaysRadioButtonsLabels {
    color: #fff;
}

.rating {
    color: #fff;
}

#submitBtnRMR {
    margin-top: 1%;
}

.rmrText {
    font-size: 1rem;
}

#daysAndPayType {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-direction: row;
    width: 75%;
}