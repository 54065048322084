.pictureAndText {
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    margin-right: 10%;
}

#now {
    display: flex;
    flex-direction: row-reverse;
}

.aboutIMGs {
    max-width: 200px;
    max-height: 200px;
    margin-bottom: 5%;
    margin-right: 5%;
}