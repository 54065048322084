#cwmBG {
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-family: Helvetica;
}

#cwmBanner {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #d50a0a;
    color: #fff;
}

#cwmBannerHeader {
    font-size: 5rem;
    font-weight: bold;
    margin-top: 1%;
    margin-bottom: 1%;
}

#cwmBannerSubText {
    font-size: 1rem;
    font-style: italic;
    margin-top: 1%;
    margin-bottom: 1%;
}

#cwmPage {
    width: 80%;
    background-color: #013369;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: .75rem;
    font-style: normal;
    font-weight: normal;
    color: #fff;
    padding-bottom: 1%;
    padding-top: 1%;
}

#cwmPageAbout {
    text-align: center;
}

#cwmPageForm {
    background-color: #013369;
    display: flex;
    width: 75%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.italic {
    font-style: italic;
}

.cwmFormElement {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.cwmInputField {
    margin-left: 5%;
    margin-right: 5%;
}