#rootPage {
  background-color: #000;
  color: #fff;
  margin-bottom: 1%; 
}

#navParent {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin-top: 1%;
}

.navChild {
  text-align: center;
  align-items: center;
}

.navChild:hover {
  border-bottom: 2px solid white;
}

.navText {
  text-decoration: none;
  font-size: 1.5em;
  font-weight: bold;
  color: #fff;
  width: 100%;
}

.fadedInOutlet {
  animation: fadeInAnimation ease 1500ms;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}