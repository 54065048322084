#screen {
    display: flex;
    width: 100%;
    justify-content: center;
    align-content: center;
}

.screenHeading {
    text-align: center;
}

.bigText {
    color: #fff;
    font-size: 4rem;
}

.mediumText {
    color: #fff;
    font-size: 1.75rem;
}

.smallText {
    text-align: left;
    color: #fff;
    font-size: 1.25rem;
}

.smallTextParagraph {
    margin-left: 5%;
    margin-right: 5%;
    word-wrap: normal;
}