#do_screen {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #18da8d;
    flex-direction: column;
}

.diceHeader {
    color: #ae1857;
    font-size: 3.25rem;
    font-weight: bold;
    margin-bottom: 1%;
}

.diceParagraph {
    color: #2033c1;
    font-size: 1.75rem;
    text-align: center;
    margin-bottom: 1%;
    margin-top: 1%;
}

.rollButton {
    background-color: #ae1857;
    border: 2px solid #fff;
    text-align: center;
    color: #fff;
    padding: 50px;
    font-size: 1.5rem;
    margin-top: 1%;
    margin-bottom: 1%;
    border-radius: 50%;
}

.rollButton:hover {
    background-color: #c65d89;

}

#do_game {
    width: 75%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: row;
    margin-bottom: 0;
    margin-top: 0;
}

#dsides {
    width: 150px;
    height: 150px;
    color: #fff;
    font-size: 7.5rem;
    text-align: center;
    background-color: #2033c1;
    border: 2px solid #fff;
}

#diceRoll {
    color: #ae1857;
    font-size: 7.5rem;
    font-weight: bold;
}