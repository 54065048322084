#bg {
    background-color: black;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
}

#banner {
    color: white;
    text-align: center;
}

#grid {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.entry {
    flex: 20%;
    border-top: 4em solid white;
    border-bottom: 4em solid white;
    border-right: 4em solid white;
    border-left: 4em solid white;
    margin: .25em;
}

#search {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

#searchBtn {
    margin: .25em;
}

#searchBar {
    margin: .25em;
    resize: none;
}
